import { useEffect } from "react"
import { useRouter } from "next/router"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

export default function Custom404() {
  const router = useRouter()

  useEffect(() => {
    router.replace("/")
  })

  return null
}

export async function getStaticProps({ locale, params }) {
  return {
    props: {
      ...params,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}